body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --primary-color: #1da1f2;
  --primary-text-color: #ffffff;
  --secondary-color: #14171a;
  --secondary-text-color: #ffffff;
  --info-color: #657786;
  --info-text-color: #ffffff;
  --background-color: #e6ecf0;
  --text-color: #14171a;
}
